import React from "react";
import { Link } from "gatsby";
import { DateTime } from "luxon";

import {
  Root,
  CounterContainer,
  LogoContainer,
  Logo,
  MainContainer,
  TextContainer,
  Title,
  Desc,
  Action,
} from "./NotFound.style";
import GlobalStyle from "../GlobalStyle";

import logoSrc from "../../images/logo-circle-black.svg";

import Countdown from "../Countdown/Countdown";

export const NotFound = () => {
  return (
    <Root>
      <GlobalStyle />

      <CounterContainer>
        <svg width={14} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M 13.848 17.858 L 8.573 13.421 L 2.74 17.017 L 5.322 10.637 L 0.093 6.2 L 6.953 6.712 L 9.575 0.333 L 11.196 7.043 L 18.056 7.554 L 12.23 11.15 L 13.848 17.858 Z"
            fill="#c49f3f"
          ></path>
        </svg>
        <span>Starts In&nbsp;</span>
        <Countdown
          variant="secondary"
          showDecor={false}
          shortNames
          expires={
            DateTime.now() <
            DateTime.fromObject(
              {
                year: 2024,
                month: 4,
                day: 1,
                hour: 0,
                minute: 0,
                second: 0,
              },
              { zone: "America/New_York" },
            )
              ? {
                  year: 2024,
                  month: 4,
                  day: 1,
                  hour: 0,
                  minute: 0,
                  second: 0,
                }
              : {
                  year: 2024,
                  month: 6,
                  day: 30,
                  hour: 23,
                  minute: 59,
                  second: 59,
                }
          }
          timezone="America/New_York"
          style={{ width: "fit-content", padding: 0 }}
        />
        <svg width={14} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M 13.848 17.858 L 8.573 13.421 L 2.74 17.017 L 5.322 10.637 L 0.093 6.2 L 6.953 6.712 L 9.575 0.333 L 11.196 7.043 L 18.056 7.554 L 12.23 11.15 L 13.848 17.858 Z"
            fill="#c49f3f"
          ></path>
        </svg>
      </CounterContainer>

      <LogoContainer>
        <Logo src={logoSrc} alt="Ole Smoky Moonshine logo" />
      </LogoContainer>

      <MainContainer>
        <TextContainer>
          <Title>404 Page Not Found</Title>
          <Desc>The page you requested does not exist.</Desc>
          <Action as={Link} to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
              <path
                fill="#fff"
                d="M14.09 17.098a.657.657 0 1 1-.93.929l-6.562-6.563a.655.655 0 0 1 0-.928l6.563-6.563a.657.657 0 0 1 .928.929L7.99 11l6.1 6.098Z"
              />
            </svg>
            <span>back to home</span>
          </Action>
        </TextContainer>
      </MainContainer>
    </Root>
  );
};
