import styled from "styled-components";

import bgSrc from "./assets/home-bg-1440.jpg";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 375px;
  min-height: 100vh;
  background: linear-gradient(
      224deg,
      rgba(0, 0, 0, 0) 24.48%,
      rgba(0, 0, 0, 0.5) 92.97%
    ),
    url(${bgSrc});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const CounterContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  color: #2b2e34;
  background-color: #d6c8ac;
  font-family: "YWFT Ultramagnetic";
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;

  svg {
    margin: 0 8px;
    transform: translateY(-3px);
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1023px) {
    padding: 12px 0;
  }

  @media (min-width: 1024px) {
    padding: 24px 0;
  }
`;

export const Logo = styled.img`
  @media (max-width: 1023px) {
    width: 80px;
  }

  @media (min-width: 1024px) {
    width: 96px;
  }
`;

export const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    padding: 150px 40px;
  }

  @media (min-width: 1024px) {
    padding: 200px 80px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
`;

export const Title = styled.h1`
  margin: 0;
  font-family: "YWFT Ultramagnetic";
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 48px;
    line-height: 54px;
  }

  @media (min-width: 1024px) {
    font-size: 70px;
    line-height: 76px;
  }
`;

export const Desc = styled.p`
  margin-top: 32px;
  margin-bottom: 64px;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 22px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Action = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  height: 48px;
  padding: 0 24px;
  color: #fff;
  background-color: #caa042;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.7px;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 24px;

  span {
    margin-top: 1px;
  }
`;
